<template>
<div class="activities-history">
    <div v-if="list.length > 0">
        <hr>
        <div class="col-md-12 col-lg-12 col-xl-12 p-0" v-for=" (item, index) in paginated" :key="index">
            <div class="activities-history-list pt-0">
                <div v-if="item.status != 'Enviado'" class="activities-history-item pt-0">
                    <h6>{{item.locationkey}}.</h6>
                    <h6> {{$t('warehouse.moved_by')}} {{item.fullNameUser}} </h6>
                    <p class="mb-0">{{$t('warehouse.date_entry')}} {{item.dateIn}}</p>
                    <p class="mb-0 text-fmedical-blue bold">{{$t('warehouse.status')}} {{item.status}}</p>
                    <p class="mb-0" v-if=" item.statusNumber != 11 && item.statusNumber != 12 && item.statusNumber != 13 && item.statusNumber != 14 && item.statusNumber != 15 && item.statusNumber != 16 && item.statusNumber != 17 ">{{$t('warehouse.quantity')}} {{item.quantity}}</p>
                </div>
                <div v-else class="activities-history-item pt-0">
                    <h6>{{item.locationkey}}.</h6>
                    <h6>{{$t('warehouse.sent_by')}} {{item.fullNameUser}} </h6>
                    <p class="mb-0">{{$t('warehouse.data_shipment')}} {{item.dateIn}}</p>
                    <p class="mb-0 text-fmedical-green bold">{{$t('warehouse.status')}} {{item.status}}</p>
                    <p class="mb-0">{{$t('warehouse.quantity')}}  {{item.quantity}}</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="col-12">
            <nav >
                <ul class="pagination justify-content-center">
                    <li class="page-item">
                        <button :disabled="isZero" class="btn btn-outline-secondary mr-2" @click="prev()">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('global.previous')}}
                        </button>
                    </li>
                    <li class="page-item">
                        <button :disabled="isEnd" class="btn btn-outline-secondary" @click="next()">
                            {{$t('global.next')}} <i class="fa fa-arrow-right ml-1"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div v-else>
        <div class="col-md-6 col-lg-12 col-xl-12 text-center">
            <b-card-title><i class="feather icon-file mr-1 fa-lg"></i></b-card-title>

            <b-card-text>
                {{$t('warehouse.no_movement')}}
            </b-card-text>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        stockDestinationList: {
            required: true,
        }
    },
    data() {
        return {
            searchT: "",
            current: 1,
            pageSize: 4,
            list: [],
            listCopy: []
        };
    },
    computed: {
        indexStart() {
            return (this.current - 1) * this.pageSize;
        },
        indexEnd() {
            return this.indexStart + this.pageSize;
        },
        paginated() {
            return this.list.slice(this.indexStart, this.indexEnd);
        },
        isZero() {
            return (this.current - 1) == 0;
        },
        isEnd() {
            return this.indexEnd >= this.list.length;
        },
        isEmpty() {
            return this.list.length == 0;
        }
    },
    methods: {
        prev() {
            this.current--;
        },
        next() {
            this.current++;
        },
        reloadComponent(list) {
            this.current = 1;
            this.searchT = "";
            this.list = list;
            this.listCopy = list;
        }
    }
};
</script>
