var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"height":"auto","fixed-header":"","item-key":"idStock","dense":"","headers":_vm.switchHeader,"items":_vm.switchList,"footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search'),"search":_vm.searchTableMain},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row pt-0"},[_c('div',{staticClass:"col-md-6 pt-0"}),_c('div',{staticClass:"col-md-6 pt-0"},[_c('v-text-field',{staticClass:"pl-1",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.searchTableMain),callback:function ($$v) {_vm.searchTableMain=$$v},expression:"searchTableMain"}})],1)])]},proxy:true},{key:"item.validDate",fn:function(ref){
    var item = ref.item;
return [_c('progressComponent',{attrs:{"date":item.validDate}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',[(_vm.switchDetails && _vm.isDetail)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"ti-stats-up mr-2",staticStyle:{"cursor":"pointer","font-size":"0.9em"},on:{"click":function($event){return _vm.viewItem(item)}}},'i',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Seguimiento")])]):_vm._e(),(_vm.updateLot && _vm.switchDetails && item.remissionType == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.updateLot && _vm.switchDetails && item.remissionType == 2)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-1 mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.stockReAsing && _vm.switchDetails && !_vm.switchSoldOut)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"fa fa-clone hand",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.moveItem(item)}}},'i',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.reassign")))])]):_vm._e(),(_vm.stockUpdateQuantify && !_vm.switchDetails)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"ti-package mr-3",staticStyle:{"cursor":"pointer","font-size":"0.9em"},on:{"click":function($event){return _vm.changeQuantity(item, 1)}}},'i',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit_quantity")))])]):_vm._e()],1)]}}],null,true)}),_c('ButtonPrintComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }