<template>
  <v-autocomplete
    :disabled="isDisabled"
    v-model="idBranchOffice"
    :items="branchOfficeList"
    @change="branchChange"
    :label="branchLabel"
  />
</template>
<script>
import { BranchOfficeRequest } from "@/core/request";

export default {
  props: {
    branchLabel: "",
  },
  data() {
    return {
      idBranchOffice: "",
      branchOfficeList: [],
      isDisabled: false,
    };
  },
  methods: {
    branchChange() {
      this.$emit("branchChange", this.idBranchOffice);
    },
    reloadComponentDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    reloadComponentIdBranchOffice(id) {
      this.idBranchOffice = { idBranchOffice: id };
    },
    reloadComponentClean() {
      this.idBranchOffice = "";
    },
  },
  created() {
    BranchOfficeRequest.listAllActive().then((response) => {
      let { data } = response.data.data;
      this.branchOfficeList = data.map((obj) => {
        return {
          value: { idBranchOffice: obj.idBranchOffice },
          text: `${obj.name} - ${obj.branchOfficeKey}`,
        };
      });
    });
  },
};
</script>
