<template>
  <div class="p-2">
    <v-data-table
      height="auto"
      fixed-header
      item-key="idStock"
      dense
      :headers="switchHeader"
      :items="switchList"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      :search="searchTableMain"
    >
      <template v-slot:top>
        <div class="row pt-0">
          <div class="col-md-6 pt-0"></div>
          <div class="col-md-6 pt-0">
            <v-text-field
              v-model="searchTableMain"
              :label="$t('table.search')"
              class="pl-1"
            ></v-text-field>
          </div>
        </div>
      </template>

   <template v-slot:[`item.validDate`]="{ item }">
      <progressComponent
      :date="item.validDate"
      />
   </template> 

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-tooltip top v-if="switchDetails && isDetail">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="ti-stats-up mr-2"
                style="cursor: pointer; font-size: 0.9em"
                @click="viewItem(item)"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            <span>Seguimiento</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="updateLot && switchDetails && item.remissionType == 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="edit(item)"
                v-bind="attrs"
                v-on="on"
                class="mb-1 mr-2"
              >
                mdi mdi-square-edit-outline
              </v-icon>
            </template>
            <span>{{ $t("table.edit") }}</span>
          </v-tooltip>
           <v-tooltip
            top
            v-if="updateLot && switchDetails && item.remissionType == 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                @click="edit(item)"
                v-bind="attrs"
                v-on="on"
                class="mb-1 mr-2"
              >
                mdi mdi-square-edit-outline
              </v-icon>
            </template>
            <span>{{ $t("table.edit") }}</span>
          </v-tooltip>
        
          <v-tooltip top v-if="stockReAsing && switchDetails && !switchSoldOut">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="fa fa-clone hand"
                aria-hidden="true"
                @click="moveItem(item)"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            <span>{{ $t("table.reassign") }}</span>
          </v-tooltip>
          <v-tooltip top v-if="stockUpdateQuantify && !switchDetails">
            <template v-slot:activator="{ on, attrs }">
              <i
                class="ti-package mr-3"
                style="cursor: pointer; font-size: 0.9em"
                @click="changeQuantity(item, 1)"
                v-bind="attrs"
                v-on="on"
              ></i>
            </template>
            <span>{{ $t("table.edit_quantity") }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import progressComponent from "@/components/warehouse/progressComponent.vue"
import { UtilFront, PermissionConstants } from "@/core";
export default {
  props: {
    switchList: {
      required: true,
    },
    isDetail: {
      required: true,
    },
    switchHeader: {
      required: true,
    },
    switchDetails: {
      required: true,
    },
    switchSoldOut: {
      required: true,
    },
    index: {
      required: true,
    },
    updateLot: {
      required: true,
    },
    stockReAsing: {
      required: true,
    },
    stockUpdateQuantify: {
      required: true,
    },
    showAction: Function,
    viewItem: Function,
    changeQuantity: Function,
    moveItem: Function,
    edit: Function,
    tags: Function,
  },
  data() {
    return {
      permissions: PermissionConstants,
      searchTableMain: "",
    };
  },
  components: {
    ButtonPrintComponent,
    progressComponent
  },
};
</script>
